@tailwind base;
@tailwind components;
@tailwind utilities;

.note-div {
    opacity: 1;
    transition: all 0s;
}

.checked-note + div {
    text-decoration: line-through;
}

.stalled-note + div {
    opacity: 0.5;
}

.important-note + div {
    text-decoration: underline;
}

.deleted-note {
    opacity: 0;
    transition: all 0.35s;
    transform: scale(0);
}

.note-list {
    min-height: 35vh;
}
